import { useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line import/no-unresolved
import { useIgTestGroup } from "@intelligems/headless/gatsby";

import { DiscountContext } from "../context/DiscountProvider";
import { applyIgPrices } from "../helpers/intelligems";
import { LuxeContext } from "../context/LuxeProvider";
import { GeoContext } from "../context/GeoProvider";

import { withALErrorBoundary } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { useLocalizedSentenceDict } from "../hooks/useSentenceDict";
import { useExclusionTags } from "../hooks/useExclusionTags";
import useDeviceDetect from "../hooks/useDeviceDetect";
import { getCookie } from "../context/helpers";
import { formatPriceAmount } from "../hooks/usePrices";

import { ALSkeleton } from "./ALComponents";
import { ComponentType } from "../constants/ComponentType";
import DISCOUNT_TYPES from "../constants/DiscountTypes";
import SubscriptionPopUp from "./SubscriptionPopUp";

import LuxeIcon from "./icons/svgs/luxe.svg";
import InfoIcon from "./icons/svgs/info.svg";

import * as Styles from "./priceContainer/styles.module.scss";
import { CartContext } from "../context/CartProvider";

const LUXE_EXCLUDED_COMPONENTS = [
  ComponentType.PDP_ADS,
  ComponentType.CP_ADS,
  ComponentType.SIDE_CART_UPSELL,
  ComponentType.FLOATING_ATC_ADS,
];

function PriceContainer({
  productId,
  selectedVariant,
  prices,
  pricesLoading,
  componentType,
  tags = [],
  optionalProductsTotalPrice = 0,
}) {
  const { discountInfo } = useContext(DiscountContext);
  const { luxe, applyLuxeDiscountToProductPrice } = useContext(LuxeContext);
  const { gePriceDetails } = useContext(GeoContext);
  const { discountStatus } = useContext(CartContext);

  const { isMobile } = useDeviceDetect();
  const exclusionTags = useExclusionTags();
  const dict = useLocalizedSentenceDict();

  const [infoPopupOpen, setInfoPopupOpen] = useState(false);

  const disableSubscriptionCookie = getCookie("subscription_disabled");
  const shouldDisableLuxe = !!disableSubscriptionCookie;

  const showLuxePrices =
    luxe.isLuxeEnabled &&
    !shouldDisableLuxe &&
    !(tags || []).some((r) => discountInfo.luxeExclusionTags?.includes(r)) &&
    !LUXE_EXCLUDED_COMPONENTS.includes(componentType);

  const luxeIcon =
    componentType === ComponentType.PDP || componentType === ComponentType.QUICKVIEW ? (
      <LuxeIcon width={isMobile ? "20" : "24"} className={Styles.price_container__luxeIcon} />
    ) : (
      <LuxeIcon height={isMobile ? "14" : "16"} className={Styles.price_container__luxeIcon} />
    );

  const { compareAtPrice, hasDiscount, finalPrice, fullPrice } = applyIgPrices(
    productId,
    selectedVariant,
    prices
  );

  const luxeFinalPrice = applyLuxeDiscountToProductPrice(
    parseFloat(finalPrice) + optionalProductsTotalPrice
  );

  let discountText = null;
  if (
    discountInfo?.type === DISCOUNT_TYPES.BOGO &&
    componentType !== ComponentType.BYOB &&
    componentType !== ComponentType.BYOB_PDP &&
    componentType !== ComponentType.QUICKVIEW &&
    !hasDiscount &&
    !(tags || []).some((r) => exclusionTags.includes(r))
  ) {
    discountText = dict.get(`BOGO {0}% OFF`, discountInfo?.value);
  }

  let wrapperClass = Styles.prices_cp;
  if (componentType === ComponentType.PDP || componentType === ComponentType.BYOB_PDP) {
    wrapperClass = Styles.prices_pdp;
  }

  if (componentType === ComponentType.PDP_ADS) {
    wrapperClass = Styles.prices_pdp_ads;
  }

  const fullPriceClass = [];
  if (hasDiscount) {
    fullPriceClass.push(Styles.price_container_before, "strikethrough", "dark-grey");
  }
  if (!compareAtPrice) {
    fullPriceClass.push(Styles.price_container_full_has_discount_right);
  }
  if (
    componentType === ComponentType.CP ||
    componentType === ComponentType.CP_ADS ||
    componentType === ComponentType.CP_OPTIONAL_PRODUCTS ||
    componentType === ComponentType.SEARCH ||
    componentType === ComponentType.BYOB ||
    componentType === ComponentType.FLOATING_ATC ||
    componentType === ComponentType.FLOATING_ATC_ADS ||
    componentType === ComponentType.WISHLIST
  ) {
    fullPriceClass.push(Styles.price_container_full_cp);
  }

  if (
    componentType === ComponentType.FLOATING_ATC ||
    componentType === ComponentType.FLOATING_ATC_ADS
  ) {
    fullPriceClass.push(Styles.price_container_full_floating, "subtext", "subtext--bold");
  }

  if (componentType === ComponentType.PRODUCT_CAROUSEL) {
    fullPriceClass.push("subtext", "subtext--bold");
  }

  if (componentType === ComponentType.SIDE_CART_UPSELL) {
    fullPriceClass.push("text", "text--bold");
  }

  const rootClassNames = ["price_container", Styles.price_container];
  if (showLuxePrices) {
    rootClassNames.push(Styles.price_container_luxe);
  }
  if (
    componentType === ComponentType.PDP ||
    componentType === ComponentType.PDP_ADS ||
    componentType === ComponentType.BYOB_PDP ||
    componentType === ComponentType.QUICKVIEW
  ) {
    rootClassNames.push(Styles.price_container_pdp);
  }
  if (
    componentType === ComponentType.FLOATING_ATC ||
    componentType === ComponentType.FLOATING_ATC_ADS
  ) {
    rootClassNames.push(Styles.price_container_floating);
  }
  if (componentType === ComponentType.PRODUCT_CAROUSEL) {
    rootClassNames.push(Styles.price_container__product_carousel);
  }

  const pricePercentageClassNames = [];
  pricePercentageClassNames.push(Styles.price_container_percentage);
  if (
    componentType === ComponentType.CP ||
    componentType === ComponentType.CP_ADS ||
    componentType === ComponentType.CP_OPTIONAL_PRODUCTS ||
    componentType === ComponentType.SEARCH ||
    componentType === ComponentType.BYOB ||
    componentType === ComponentType.WISHLIST
  ) {
    rootClassNames.push(Styles.price_container__cp);
    pricePercentageClassNames.push(Styles.price_container_percentage__productCollection);
  }

  if (
    componentType === ComponentType.FLOATING_ATC ||
    componentType === ComponentType.FLOATING_ATC_ADS
  ) {
    pricePercentageClassNames.push(Styles.floating__price_container_percentage);
  }

  const abTestPrices = useMemo(() => {
    let nextLuxePrice = null;
    let nextFullPrice = null;
    let nextFinalPrice = null;
    let percentageOff = 0;
    if (
      discountInfo &&
      !(tags || []).some((t) => (discountInfo?.exclusionTags || []).includes(t))
    ) {
      if (discountStatus && (discountStatus.next || discountStatus.current)) {
        if (discountStatus.next) {
          percentageOff = discountStatus.next[1];
        } else {
          percentageOff = discountStatus.current[1];
        }
      }
    }

    const isOnlyExtraDiscount = (tags || []).some((r) => luxe?.extraDiscountTags?.includes(r));

    // Calculate (next) AL Luxe Price
    let nextPercentageOffLuxe = luxe.discountLuxe * 100;
    if (!isOnlyExtraDiscount) {
      nextPercentageOffLuxe += percentageOff;
    }
    nextLuxePrice = parseFloat(finalPrice) - parseFloat(finalPrice) * (nextPercentageOffLuxe / 100);

    // Calculate (next) Full Price
    nextFullPrice = parseFloat(fullPrice) - parseFloat(fullPrice) * (percentageOff / 100);

    // Calculate (next) Final Price
    nextFinalPrice = parseFloat(finalPrice) - parseFloat(finalPrice) * (percentageOff / 100);

    return {
      nextLuxePrice,
      nextFullPrice,
      nextFinalPrice,
    };
  }, [discountStatus, finalPrice, fullPrice, luxe.discountLuxe, luxe?.extraDiscountTags, tags]);

  let testFullPrice = formatPriceAmount(
    parseFloat(fullPrice),
    gePriceDetails?.CurrencyCode,
    gePriceDetails?.CountryCode
  );
  let testFinalPrice = formatPriceAmount(
    parseFloat(finalPrice),
    gePriceDetails?.CurrencyCode,
    gePriceDetails?.CountryCode
  );
  let testLuxeFinalPrice = luxeFinalPrice;

  // Check if product is excluded from the sale
  const isExcluded = tags.some((r) => discountInfo?.luxeExclusionTags?.includes(r));
  // Pull AB Test Group
  const experimentId = "78da0803-4391-4fb3-8f41-c8d9903b9516";
  const test = useIgTestGroup(experimentId);
  const testGroupName = test?.testGroup?.name;

  if (!isExcluded) {
    if (testGroupName === "Luxe + Full") {
      testLuxeFinalPrice = formatPriceAmount(
        parseFloat(abTestPrices.nextLuxePrice),
        gePriceDetails?.CurrencyCode,
        gePriceDetails?.CountryCode
      );
      if (hasDiscount) {
        testFinalPrice = formatPriceAmount(
          parseFloat(abTestPrices.nextFinalPrice),
          gePriceDetails?.CurrencyCode,
          gePriceDetails?.CountryCode
        );
      } else {
        testFullPrice = formatPriceAmount(
          parseFloat(abTestPrices.nextFullPrice),
          gePriceDetails?.CurrencyCode,
          gePriceDetails?.CountryCode
        );
      }
    } else if (testGroupName === "Luxe Only") {
      testLuxeFinalPrice = formatPriceAmount(
        parseFloat(abTestPrices.nextLuxePrice),
        gePriceDetails?.CurrencyCode,
        gePriceDetails?.CountryCode
      );
    }
  }

  if (pricesLoading) {
    return (
      <div
        className={`${
          componentType === ComponentType.CP || componentType === ComponentType.CP_ADS
            ? Styles.skeletonWrapper
            : ""
        }`}
      >
        <ALSkeleton width="31px" height="18px" />
      </div>
    );
  }

  if (!prices || prices?.price === undefined) {
    return null;
  }

  return (
    <div className={rootClassNames.join(" ")}>
      {showLuxePrices && (
        <div className={`${Styles.price_container__luxe} ${Styles[componentType.toLowerCase()]}`}>
          {luxeIcon}
          <p className={Styles.price_container__luxePrice} data-testid="luxe-price">
            {testLuxeFinalPrice}
          </p>
          {componentType === ComponentType.PDP && (
            <button type="button" onClick={() => setInfoPopupOpen(!infoPopupOpen)}>
              <InfoIcon
                width={isMobile ? "15" : "20"}
                className={Styles.price_container__luxeIconInfo}
              />
            </button>
          )}
          <SubscriptionPopUp isOpen={infoPopupOpen} onClose={() => setInfoPopupOpen(false)} />
        </div>
      )}
      <div>
        <div className={wrapperClass}>
          {fullPrice && (
            <p className={fullPriceClass.join(" ")} data-testid="fullPrice">
              {testFullPrice}
            </p>
          )}

          {hasDiscount && finalPrice && (
            <p
              className={`subtext ${Styles.price_container_final} ${
                !luxe.isLuxeEnabled ? "poppy subtext--bold" : ""
              }`}
              data-testid="finalPrice"
            >
              {testFinalPrice}
            </p>
          )}
        </div>

        {discountText && (
          <div
            className={pricePercentageClassNames.join(" ")}
            data-discount={prices.discount_given}
            data-testid="discountText"
          >
            {discountText}
          </div>
        )}
      </div>
    </div>
  );
}

PriceContainer.propTypes = {
  componentType: PropTypes.string,
  optionalProductsTotalPrice: PropTypes.number,
  tags: PropTypes.arrayOf(PropTypes.string),
  prices: PropTypes.object,
  pricesLoading: PropTypes.bool,
};

export default withALErrorBoundary({
  name: "PriceContainer",
  priority: "P1",
})(PriceContainer);
