import axios from "axios";
import { navigate } from "gatsby";
import { useState, useEffect, useContext, createContext, useCallback, useReducer } from "react";
import { data } from "@blotoutio/edgetag-sdk-js";
import PropTypes from "prop-types";
import { useLocation } from "@reach/router";
import { sendUserSignIn as gtmSendUserSignIn, useTracking } from "./Tracking";
import { useCurrentCountryCode } from "../hooks/usePrices";
import { useALError, alReportError } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { getShopifyStoreByCountry } from "../../locale-shopifies";

import {
  getCookie,
  isBrowser,
  getAllUrlParams,
  removeQueryParam,
  getCustomerAccessTokenCreateWithMultipass,
} from "./helpers";
import { Popup } from "../components/Popup";
import CreateAccount from "../components/account/CreateAccount";
import Login from "../components/account/Login";
import Confirmation from "../components/account/Confirmation";

import REDIRECT_TYPES from "../constants/RedirectType";

import "../components/account/accountPopup.scss";

const TYPE_CREATE_ACCOUNT = "signup";
const TYPE_LOGIN = "login";
const TYPE_CONFIRM = "confirm";

// Popups that are exposed through the ?auth=<type> url param and can be triggered with said parameter
const URL_EXPOSED_TYPES = [TYPE_CREATE_ACCOUNT, TYPE_LOGIN];

const noop = () => {};

export const AuthContext = createContext({
  login: noop,
  logout: noop,
  accountDetails: null,
  shouldClearWishlist: false,
  showCreateAccount: noop,
  showLogin: noop,
  showConfirmation: noop,
});

function useLocalStorage(key, initialValue) {
  const localStorageReducer = (state, action) => {
    let valueToStore = state;
    try {
      valueToStore = action instanceof Function ? action(state) : action;
      if (window) {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (err) {
      // Local Storage or func action fail
      console.error(err);
      alReportError(err);
    }
    return valueToStore;
  };
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useReducer(localStorageReducer, initialValue, () => {
    try {
      if (isBrowser) {
        // Get from local storage by key
        const item = window && window.localStorage.getItem(key);
        // Parse stored json or if none return initialValue
        return item ? JSON.parse(item) : initialValue;
      }
      return initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      alReportError(error);
      return initialValue;
    }
  });
  return [storedValue, setStoredValue];
}

function useProvideAuth() {
  const { sendReport } = useALError();

  const countryCode = useCurrentCountryCode();
  const store = getShopifyStoreByCountry(countryCode);
  const [accountDetails, setAccountDetails] = useLocalStorage(`${store.name}-accountDetails`, null);
  const [shouldClearWishlist, setShouldClearWishlist] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const { trackUserSignup, trackUserLogin, trackAccountErrors } = useTracking();

  const location = useLocation();

  const [authModalComponent, setAuthModalComponent] = useState(null);
  const [authModalComponentProps, setAuthModalComponentProps] = useState({});

  // When resetting authModalComponent to null (i.e. closing modal)
  // Reset component props and any lingering &auth=<type> query param that might have it triggered
  useEffect(() => {
    if (!authModalComponent) {
      // Reset component props
      setAuthModalComponentProps({});

      // Remove &auth=<type> parameter
      removeQueryParam("auth");
    }
  }, [authModalComponent]);

  /*
    Listen to location changes - for the auth search parameter
    URL_EXPOSED_TYPES contains popups that can be opened through the url query parameter
    i.e. http://localhost:8888/collections/bestsellers?auth=login will open the login popup
  */
  useEffect(() => {
    const queryParams = getAllUrlParams(location.href);
    if (queryParams.auth && URL_EXPOSED_TYPES.indexOf(queryParams.auth) > -1) {
      setAuthModalComponent(queryParams.auth);
    }

    const isValidRedirect = !Object.values(REDIRECT_TYPES).some(
      (redirect) => redirect === queryParams.redirect
    );
    if (queryParams.magic && isValidRedirect) {
      axios
        .get(`/.netlify/functions/magic`, {
          params: {
            token: queryParams.magic,
            redirectUrl: queryParams.redirect || `${window.location.origin}/account/`,
            countryCode,
          },
        })
        .then((res) => {
          const { token, authUrl } = res.data;
          getCustomerAccessTokenCreateWithMultipass(token, store).then((customerAccessToken) => {
            const { accessToken, expiresAt } = customerAccessToken;

            document.cookie = `${store.name}-accessToken=${accessToken}; expires=${new Date(
              expiresAt
            ).toGMTString()}; path=/; Secure`;

            // Auth URL is a Shopify multipass URL that will redirect to the 'redirectUrl'
            window.location.href = authUrl;
          });
        });
    } else if (queryParams.redirect && !queryParams.magic && isValidRedirect) {
      window.location.href = queryParams.redirect;
    }
  }, [countryCode, location, store]);

  const signup = async (email, password, firstName, lastName, redirectUrl = null) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `/.netlify/functions/account-register`,
        {
          email,
          password,
          firstName,
          lastName,
          countryCode,
          redirectUrl: redirectUrl || window.location.href,
        },
        {
          throwHttpErrors: false,
        }
      );

      try {
        data({
          email,
          firstName,
          lastName,
          country: countryCode,
        });
      } catch (error) {
        sendReport(error, { name: "JustUnoTracking", priority: "P1" });
      }

      const { authUrl, token, customer } = res.data;
      trackUserSignup(email, res.status, res.data, res.status === 200 && authUrl && token);
      if (res.status === 200 && authUrl && token) {
        setError(null);

        const customerAccessToken = await getCustomerAccessTokenCreateWithMultipass(token, store);
        const { accessToken, expiresAt } = customerAccessToken;

        document.cookie = `${store.name}-accessToken=${accessToken}; expires=${new Date(
          expiresAt
        ).toGMTString()}; path=/; Secure`;

        setAccountDetails(customer);

        window.location.href = authUrl;

        return true;
      }
      setError(`Failed to register: ${res.data}`);
      trackAccountErrors({
        action: "SIGNUP",
        errorMessage: `Failed to register: ${res.data}`,
        userEmail: email,
      });
    } catch (e) {
      const res = e.response;
      trackAccountErrors({
        action: "SIGNUP",
        errorMessage: `Failed to register: ${res.data}`,
        userEmail: email,
      });
      setError(`Failed to register: ${res ? res.data : JSON.stringify(e)}`);
      sendReport(e, { name: "AuthProvider", priority: "P5" });
    } finally {
      setIsLoading(false);
    }
  };

  const login = async (email) => {
    try {
      setIsLoading(true);
      const queryParams = getAllUrlParams(location.href);
      const redirectUrl = queryParams["auth-redirect"] || window.location.href;
      const response = await axios.post("/.netlify/functions/magic", {
        countryCode,
        redirectUrl,
        email,
      });

      if (response.status === 200) {
        gtmSendUserSignIn(email);
        trackUserLogin(email);
        return true;
      }
      return false;
    } catch (e) {
      sendReport(e, { name: "AuthProvider", priority: "P5" });
      const { response } = e;
      trackAccountErrors({
        action: "LOGIN",
        errorMessage: response.data.message, // To Fix, not passing the right info to <Login /> component
        userEmail: email,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const logout = useCallback(async () => {
    setIsLoading(true);
    const accessToken = getCookie(`${store.name}-accessToken`);

    try {
      if (accessToken) {
        const res = await axios.get(
          `/.netlify/functions/account-logout?countryCode=${countryCode}&accessToken=${accessToken}`
        );
        navigate("/");
        if (res.status !== 200) {
          trackAccountErrors({ action: "LOGOUT", errorMessage: res.data });
        }
      }
    } catch (e) {
      sendReport(e, { name: "AuthProvider", priority: "P5" });
    } finally {
      setAccountDetails(null);
      setShouldClearWishlist(true);
      setIsLoading(false);
    }
  }, [countryCode, sendReport, setAccountDetails, store.name, trackAccountErrors]);

  // If user token found:
  // - Load account data
  // - Invalidate data if token invalid (expired or other reasons)
  // - Update on store change
  useEffect(() => {
    const getAccountDetail = async () => {
      try {
        setIsLoading(true);
        const accessToken = getCookie(`${store.name}-accessToken`);

        if (accessToken) {
          const res = await axios.get(`/.netlify/functions/account`, {
            params: { countryCode, accessToken },
          });
          if (res.status !== 200 || !res.data.customer) {
            // invalid token and no error from us (backend)
            await logout();
          } else {
            setAccountDetails(res.data.customer);
          }
        }
      } catch (err) {
        sendReport(err, { name: "AuthProvider", priority: "P5" });
      } finally {
        setIsLoading(false);
      }
    };
    getAccountDetail();
  }, [countryCode, store.name, logout, setAccountDetails, sendReport]);

  const showCreateAccount = () => setAuthModalComponent(TYPE_CREATE_ACCOUNT);
  const showLogin = () => setAuthModalComponent(TYPE_LOGIN);

  const showConfirmation = ({ actionLabel, onAction, text }) => {
    setAuthModalComponent(TYPE_CONFIRM);
    setAuthModalComponentProps({ actionLabel, onAction, text });
  };

  const closeAuthModal = () => {
    setAuthModalComponent(null);
  };

  return {
    isLoading,
    error,
    accountDetails,
    setAccountDetails,
    shouldClearWishlist,
    login,
    logout,
    signup,
    authModalComponent,
    authModalComponentProps,
    closeAuthModal,
    showCreateAccount,
    showLogin,
    showConfirmation,
  };
}

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
  const auth = useProvideAuth();

  let modalContent = null;
  switch (auth.authModalComponent) {
    case TYPE_CREATE_ACCOUNT:
      modalContent = (
        <CreateAccount {...auth.authModalComponentProps} onClose={auth.closeAuthModal} />
      );
      break;
    case TYPE_LOGIN:
      modalContent = <Login {...auth.authModalComponentProps} onClose={auth.closeAuthModal} />;
      break;
    case TYPE_CONFIRM:
      modalContent = (
        <Confirmation {...auth.authModalComponentProps} onClose={auth.closeAuthModal} />
      );
      break;
    default:
      modalContent = null;
      break;
  }
  return (
    <AuthContext.Provider value={auth}>
      {children}
      <Popup
        open={!!modalContent}
        modal
        closeOnDocumentClick
        className="account-popup"
        onClose={auth.closeAuthModal}
      >
        {modalContent}
      </Popup>
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
